import React from 'react'
import { useTranslation } from 'react-i18next'
import { CDN_URL } from '../../../constants/siteConstant'
import './SimpleTransparentSection.scss'
import AccordionWrapper from '../../common/AccordionWrapper'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const SimpleTransparentSection = ({ sectionRef }) => {
  const { t } = useTranslation(['simpletransparent'])

  const navigate = useNavigate()
  const goToRegisterPage = () => {
    navigate('/register')
  }

  return (
    <div className='simpleRootContainer d-flex items-center justify-content-between' ref={sectionRef}>
      <div className='d-flex'>
        <img
          className='maskImg'
          src={CDN_URL + '/landing-page/mask.jpeg'}
          alt='mask-img'
          width={585}
          height={960}
        />
        <div className='d-flex flex-d-column simpleTransContainer'>
          <span className='simpleTitleSpan font-Inconsolata font-weight-700 leading-52 -tracking-04'>
            {t('sectionTitle')}
          </span>
          <span
            className='font-Inconsolata font-weight-500 gp-text22 gp-pt32 leading-32' style={{ color: '#919191', maxWidth: '570px', textAlign: 'left' }}
          >{t('sectionSubtitle')}
          </span>
          <Button
            className='action-btn register-now-btn'
            onClick={goToRegisterPage}
          >
            {t('registerNow')}
          </Button>
          <div className='maskMobileContainer'>
            <img
              src={CDN_URL + '/landing-page/mask.jpeg'}
              alt='mask-cont'
              width={330}
              height={433}
              style={{ objectFit: 'cover' }}
            />
          </div>

          <div className='d-flex flex-d-column gp-p20'>
            <AccordionWrapper
              title1={t('firstPoint')}
              subTitle1={t('firstPointContent')}
              title2={t('secondPoint')}
              subTitle2={t('secondPointContent')}
              title3={t('thirdPoint')}
              subTitle3={t('thirdPointContent')}
              title4={t('fourthPoint')}
              subTitle4={t('fourthPointContent')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleTransparentSection
