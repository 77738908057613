import React from 'react'
import { useTranslation } from 'react-i18next'
import { CDN_URL } from '../../../constants/siteConstant'
import ProductFeaturesCard from '../ProductFeaturesCard'
import { Button } from '@mui/material'
import './ProductFeatures.scss'
import AccordionWrapper from '../../common/AccordionWrapper'

const ProductFeatures = ({ scrollToContact }) => {
  const { t } = useTranslation(['productFeatures'])

  return (
    <>
      <div className='productFeaturesRoot d-flex items-center flex-d-column' style={{ backgroundImage: `url(${CDN_URL}/landing-page/product-bg-image.svg)` }}>
        <div className='uppercase gp-text12 font-Manrope font-weight-700 tracking-2'>{t('easyToUse')}</div>
        <span className='productFeaturesTitleSpan -tracking-04 font-Inconsolata font-weight-700 leading-52'>
          {t('productFeaturesTitle')}
        </span>
        <span
          className='font-Inconsolata font-weight-500 gp-text22 leading-32 pf-subtitle'
        >
          {t('productFeaturesSubtitle')}
        </span>
        <div
          className='productFeaturesCardContainer d-flex gp-30'
        >
          <ProductFeaturesCard
            cardTitle={t('firstCardTitle')}
            cardContent={t('firstCardContent')}
          />
          <ProductFeaturesCard
            cardTitle={t('secondCardTitle')}
            cardContent={t('secondCardContent')}
          />
          <ProductFeaturesCard
            cardTitle={t('thirdCardTitle')}
            cardContent={t('thirdCardContent')}
          />

        </div>
        <div className='mac-analytics-cont'>
          <img src={`${CDN_URL}/landing-page/macbook-pro-2x.png`} className='macbook-img' />
        </div>
      </div>
      <div
        className='accordionRootContainer d-flex w-full justify-content-center bb'
      >
        <div className='accordionSubContainer d-flex w-full justify-content-space-between mw-1300'>
          <div
            className='d-flex flex-d-column' style={{
              flex: 2,
              maxWidth: '679px'
            }}
          >
            <AccordionWrapper
              title1={t('firstAccordionTitle')}
              subTitle1={t('firstAccordionContent')}
              title2={t('secondAccordionTitle')}
              subTitle2={t('secondAccordionContent')}
              title3={t('thirdAccordionTitle')}
              subTitle3={t('thirdAccordionContent')}
              title4={t('fourthAccordionTitle')}
              subTitle4={t('fourthAccordionContent')}
              title5={t('fiftyAccordionTitle')}
              subTitle5={t('fifthAccordionContent')}
              title6={t('sixthAccordionTitle')}
              subTitle6={t('sixthAccordionContent')}
            />
          </div>
          <div
            className='d-flex flex-d-column' style={{
              rowGap: '15px',
              maxWidth: '270px'
            }}
          >
            <span
              className='font-Manrope font-weight-700 gp-text14 leading-26 tracking-2 uppercase gp-pt20' style={{
                color: '#919191'
              }}
            >
              {t('question')}
            </span>
            <Button
              style={{
                backgroundColor: '#6926D9',
                borderRadius: '100px',
                padding: '9px 16px 9px 16px',
                color: 'white',
                textTransform: 'none',
                maxWidth: '200px',
                fontFamily: 'DMSans',
                fontWeight: 500,
                fontSize: '16px'
              }}
              onClick={scrollToContact}
            >
              {t('lookFAQ')}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductFeatures
