import React from 'react'
import { useTranslation } from 'react-i18next'

const FooterLinksList = ({ title, list, scrollToAdvantageSection, scrollToSection, scrollToContact, scrollToTopOfPage }) => {
  const { t } = useTranslation(['footer'])
  return (
    <div className='d-flex flex-d-column'>
      <span
        className='font-Manrope gp-text18 font-weight-500 leading-28 text-white'
        style={{
          paddingBottom: '27px'
        }}
      >
        {title}
      </span>
      <div
        className='d-flex flex-d-column' style={{
          rowGap: '20px'
        }}
      >
        {list.map((el, index) => {
          let onClickHandler = scrollToTopOfPage
          switch (el) {
            case t('advantage'):
              onClickHandler = scrollToAdvantageSection
              break
            case t('functions'):
              onClickHandler = scrollToSection
              break
            case t('contact'):
              onClickHandler = scrollToContact
              break
            default:
              onClickHandler = scrollToTopOfPage
          }
          return (
            <span
              className='font-Manrope gp-text16 text-white font-weight-400 leading-26 cp footer-item'
              key={index}
              onClick={onClickHandler}
            >
              {el}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default FooterLinksList
