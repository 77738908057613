import React from 'react'
import { goToHomePage } from '../../helpers/Utils'
// import { Link } from 'react-router-dom'

export default function Copyright () {
  return (
    <div className='font-Manrope copyright'>
      {'Copyright © '}
      <span className='clr_919191 footer_link cp' onClick={goToHomePage}>helloparts</span>{' '}
      {new Date().getFullYear()}
      .
    </div>
  )
}
