/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import SnackBarComponent from './components/common/Snackbar'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import { ROUTES_WITHOUT_SIDEBAR } from '../src/router/index'

import './assets/styleGuides/panelMargins.scss'
import './assets/styleGuides/panelPadding.scss'
import './assets/styleGuides/panelTypography.scss'
import './assets/styleGuides/panelUtility.scss'
import { bindActionCreators } from 'redux'
import * as authActions from './actions/auth'
import * as dashboardActions from './actions/dashboard'

function App (props) {
  const [loader, setLoader] = useState(false)
  const [snackBar, setSnackBar] = useState(false)
  const [displaymessage, setdisplayMsg] = useState(null)

  useEffect(() => {
    window.addEventListener('showLoader', showLoader)
    window.addEventListener('hideLoader', hideLoader)
    window.addEventListener('showSnackBar', showSnackBar)
    window.addEventListener('hideSnackBar', hideSnackBar)
    return () => {
      window.removeEventListener('showLoader', showLoader)
      window.removeEventListener('hideLoader', hideLoader)
      window.removeEventListener('showSnackBar', showSnackBar)
      window.removeEventListener('hideSnackBar', hideSnackBar)
    }
  }, [])

  useEffect(() => {
    fetchGoogleAnalyticsTrackingId()
    return () =>
      removeGoogleAnalyticsScript()
  }, [])

  const fetchGoogleAnalyticsTrackingId = () => {
    const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID

    if (gaTrackingId) {
      const script = document.createElement('script')
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`
      script.async = true
      script.id = 'ga-script'
      document.body.appendChild(script)

      window.dataLayer = window.dataLayer || []
      // eslint-disable-next-line no-inner-declarations
      function gtag () { dataLayer.push(arguments) }
      gtag('js', new Date())

      gtag('config', gaTrackingId)
    }
  }

  const removeGoogleAnalyticsScript = () => {
    const script = document.getElementById('ga-script')
    if (script) {
      document.body.removeChild(script)
    }
  }

  const showLoader = () => {
    setLoader(true)
  }

  const hideLoader = () => {
    setLoader(false)
  }

  const showSnackBar = (event) => {
    setSnackBar(true)
    setdisplayMsg(event?.detail?.displayMsg || event?.detail)
  }

  const hideSnackBar = () => {
    setSnackBar(false)
  }

  return (
    <Router>
      <div>
        {loader &&
          <div className='transform-to-center'>
            <CircularProgress />
          </div>}
        {snackBar &&
          <div>
            <SnackBarComponent open={snackBar} handleClose={hideSnackBar} displayMsg={displaymessage || props?.authState?.authResponse} />
          </div>}
        <Routes>
          {ROUTES_WITHOUT_SIDEBAR(props)?.map((route) => (
            <Route
              path={route.path}
              key={route.key}
              exact={route.exact}
              element={route.component}
            />
          ))}
        </Routes>
      </div>
    </Router>
  )
}

function mapStateToProps (state) {
  return {
    authState: state.authReducer,
    dashboardState: state.dashboardReducer
  }
}

function mapDispatchToProps (dispatch) {
  return {
    actions: {
      authActions: bindActionCreators(authActions, dispatch),
      dashboardActions: bindActionCreators(dashboardActions, dispatch)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
