const BE_ENDPOINTS = {
  development: 'https://staging-authenticator-api.oobicar.com',
  staging: 'https://staging-authenticator-api.oobicar.com',
  production: 'https://authenticator-api.helloparts.com',
  pre_prod: 'https://authenticator-api.p2double-t.de'
}

/* eslint-disable */
const currentEnv = process.env.REACT_APP_NODE_ENV

export const ROOT_URL = BE_ENDPOINTS[currentEnv]
export const SIGNUP_API = `${ROOT_URL}/api/v1/sign-up`
export const LOGIN_API = `${ROOT_URL}/api/v1/login`
export const LOGOUT_API = `${ROOT_URL}/api/v1/logout`
export const FORGOT_PASSWORD_API = `${ROOT_URL}/api/v1/forgot-password`
export const RESET_PASSWORD_API = `${ROOT_URL}/api/v1/reset-password`
export const RESET_PASSWORD_API_FOR_NEW_EXPIRE_USER = `${ROOT_URL}/api/v1/new-user/reset-password`

export const DASHBOARD_URL = '/dashboard'

const WORKSHOP_BE_ENDPOINTS = {
  development: 'https://staging-workshop-api.oobicar.com/api/v1',
  staging: 'https://staging-workshop-api.oobicar.com/api/v1',
  production: 'https://workshop-api.helloparts.com/api/v1',
  pre_prod: 'https://workshop-api.p2double-t.de/api/v1'
}

export const ROOT_WORKSHOP_URL = WORKSHOP_BE_ENDPOINTS[currentEnv]

export const LEAD_API = `${ROOT_WORKSHOP_URL}/lead`
export const DMS_LIST_API = `${ROOT_WORKSHOP_URL}/lead/dms`
export const PD_LIST_API = `${ROOT_WORKSHOP_URL}/lead/part-dealers`
export const SAVE_LEAD_API = `${ROOT_URL}/api/v1/lead`
export const CONTACT_INFO_API = `${ROOT_URL}/api/v1/contact-info`

const CENTRAL_PORTAL_URLS = {
  development: 'https://staging-central-portal.oobicar.com',
  staging: 'https://staging-central-portal.oobicar.com',
  production: 'https://helloparts.com',
  pre_prod: 'https://p2double-t.de'
}

export const BE_STAGING_CENTRAL_URL = CENTRAL_PORTAL_URLS[currentEnv]

export const RESET_PASSWORD_URL = `${BE_STAGING_CENTRAL_URL}/reset-password`

