import { AUTH_INITIAL_STATE } from '../constants/initialState'
import { SINGUP_IN_PROGRESS, SINGUP_COMPLETED, FORGOT_PASSWORD, FORGOT_PASSWORD_ERROR, SINGUP_ERROR, SINGUP_ERROR_FIXED, LOGIN_IN_PROGRESS, LOGIN_COMPLETE, LOGIN_IN_ERROR, LOGOUT_COMPLETED, RESET_PASSWORD, RESET_PASSWORD_ERROR } from '../constants/actionTypes'

export default function authReducer (state = AUTH_INITIAL_STATE, action) {
  switch (action.type) {
    case SINGUP_IN_PROGRESS:
      return Object.assign({}, state, {
        signupInProgress: true,
        signupAttemptFailed: false,
        signupCompleted: false,
        logoutCompleted: false,
        authResponse: {},
        isError: { isError: false, errorMsg: '' }
      })

    case SINGUP_COMPLETED:
      return Object.assign({}, state, {
        signupInProgress: false,
        signupAttemptFailed: false,
        signupCompleted: true,
        authResponse: action?.data || ''
      })

    case SINGUP_ERROR:
      return Object.assign({}, state, {
        signupInProgress: false,
        signupCompleted: false,
        signupAttemptFailed: true,
        authResponse: action?.data || '',
        isError: { isError: true, errorMsg: action?.data?.error }
      })

    case SINGUP_ERROR_FIXED:
      return Object.assign({}, state, {
        signupInProgress: false,
        signupAttemptFailed: false,
        isError: { isError: true, errorMsg: '' }
      })

    case LOGIN_IN_PROGRESS:
      return Object.assign({}, state, {
        loginInProgress: true,
        loginFlowComplete: false,
        loginError: false,
        logoutCompleted: false,
        authResponse: {},
        isError: { isError: false, errorMsg: '' }
      })

    case LOGIN_COMPLETE:
      return Object.assign({}, state, {
        loginInProgress: false,
        loginFlowComplete: true,
        authResponse: action?.data || ''
      })
    case LOGIN_IN_ERROR:
      return Object.assign({}, state, {
        loginInProgress: false,
        loginFlowComplete: false,
        loginError: true,
        authResponse: action?.data || '',
        isError: { isError: true, errorMsg: action?.data?.message }
      })
    case LOGOUT_COMPLETED:
      return Object.assign({}, state, {
        logoutCompleted: true,
        authResponse: action?.data
      })

    case FORGOT_PASSWORD:
      return Object.assign({}, state, {
        forgotPassword: true,
        authResponse: action?.data,
        forgotPasswordError: false,
        isError: { isError: false, errorMsg: '' }
      })

    case FORGOT_PASSWORD_ERROR:
      return Object.assign({}, state, {
        forgotPasswordError: true,
        authResponse: action?.data || ''
      })

    case RESET_PASSWORD:
      return Object.assign({}, state, {
        resetPassword: true,
        resetPasswordError: false,
        authResponse: action?.data || ''
      })
    case RESET_PASSWORD_ERROR:
      return Object.assign({}, state, {
        resetPasswordError: true,
        authResponse: action?.data || ''
      })
    default:
      return state
  }
}
