import React, { useRef } from 'react'
import LandingHero from '../landing-page/LandingHero/LandingHero'
import AdvantagesHelloparts from '../landing-page/AdvantagesHelloparts/AdvantagesHelloparts'
import MiniBanner from '../landing-page/MiniBanner/MiniBanner'
import { useTranslation } from 'react-i18next'
import SimpleTransparentSection from '../landing-page/SimpleTransparentSection/SimpleTransparentSection'
import ProductFeatures from '../landing-page/ProductFeatures/ProductFeatures'
// import PartsProfessionals from '../landing-page/PartsProfessionals/PartsProfessionals'
import AlwaysAvailable from '../landing-page/AlwaysAvailable/AlwaysAvailable'
import Footer from '../landing-page/Footer/Footer'

const LandingPage = () => {
  const { t } = useTranslation(['minibanner'])
  const sectionRef = useRef(null)
  const contactRef = useRef(null)
  const advantageRef = useRef(null)
  const homeRef = useRef(null)

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const scrollToSection = () => {
    scrollToRef(sectionRef)
  }

  const scrollToContact = () => {
    scrollToRef(contactRef)
  }

  const scrollToAdvantageSection = () => {
    scrollToRef(advantageRef)
  }

  const scrollToTopOfPage = () => {
    scrollToRef(homeRef)
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <LandingHero
        scrollToSection={scrollToSection}
        scrollToAdvantageSection={scrollToAdvantageSection}
        scrollToContact={scrollToContact}
        homeRef={homeRef}
      />
      <AdvantagesHelloparts advantageRef={advantageRef} />
      <MiniBanner title={t('registerNowTitle')} buttonText={t('registerNow')} />
      <SimpleTransparentSection sectionRef={sectionRef} />
      <ProductFeatures scrollToContact={scrollToContact} />
      {/* <PartsProfessionals /> */}
      <AlwaysAvailable contactRef={contactRef} />
      <Footer
        scrollToContact={scrollToContact}
        scrollToSection={scrollToSection}
        scrollToAdvantageSection={scrollToAdvantageSection}
        scrollToTopOfPage={scrollToTopOfPage}
      />
    </div>
  )
}

export default LandingPage
