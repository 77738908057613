import { combineReducers } from 'redux'
import authReducer from './authReducer'
import dashboardReducer from './dashboardReducer'

const mainReducer = combineReducers({
  authReducer,
  dashboardReducer

})

export default mainReducer
