import React from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Header from '../../common/Header/Header'
import './LandingHero.scss'

const LandingHero = ({ scrollToSection, scrollToAdvantageSection, scrollToContact, homeRef }) => {
  const { t } = useTranslation(['landinghero'])
  const navigate = useNavigate()

  const goToRegisterPage = () => {
    navigate('/register')
  }

  return (
    <div
      className='rootDiv h-full bg-cover vertical-center flex-d-column' ref={homeRef}
    >
      <Header scrollToSection={scrollToSection} scrollToAdvantageSection={scrollToAdvantageSection} scrollToContact={scrollToContact} />
      <div className='d-flex items-center flex-d-column titleDiv'>
        <span className='titleSpan font-Inconsolata font-weight-700 d-flex text-white -tracking-1 text-center'>
          {t('heroTitle')}
        </span>
        <span className='subtitleSpan font-Manrope font-weight-500 leading-28 text-white text-center'>
          {t('heroSubtitle')}
        </span>
      </div>
      <div className='buttonDiv w-full d-flex justify-content-center gap-x-10 gp-pt36'>
        <Button
          className='hero-btn get-started'
          onClick={goToRegisterPage}
        >
          <span className='font-Manrope gp-text18 font-weight-500 leading-26 text-white'>{t('getStarted')}</span>
        </Button>
        <Button
          className='hero-btn how-it-works'
          onClick={scrollToSection}
        >
          <span className='font-Manrope gp-text18 font-weight-500 leading-26'>{t('howItWorks')}</span>
        </Button>
      </div>
      <span className='font-Manrope font-weight-400 gp-text16 leading-26 text-white text-center pt-21'>
        {t('byRegisteringInfo')}
      </span>
    </div>
  )
}

export default LandingHero
