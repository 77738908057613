export const AUTH_INITIAL_STATE = {
  signupInProgress: false,
  signupAttemptFailed: false,
  signupCompleted: false,
  loginInProgress: false,
  loginFlowComplete: false,
  loginError: false,
  forgotInProgress: false,
  forgotFlowComplete: false,
  resetPwdInProgress: false,
  resetFlowComplete: false,
  isError: { isError: false, errorMsg: '' },
  logoutCompleted: false,
  authResponse: {},
  forgotPasswordError: false
}

export const DASHBOARD_INITIAL_STATE = {
  isDashboardShowing: false,
  showDashboardPage: false,
  dashboardData: {}
}
