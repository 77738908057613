import React, { useState } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Box, Button, CssBaseline, Container, Grid, IconButton, InputAdornment, InputLabel, TextField, Link, MenuItem, Select, Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { hideLoader, showLoader, showSnackBar, validationForm } from '../../helpers/Utils'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import Copyright from '../common/Copyright'

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme()

export default function SignUp (props) {
  const { authActions, authState } = props
  const [showPassword, setShowPassword] = useState(false)
  const [selectInitState, setSelectInitState] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    company_name: '',
    contact_number: '',
    service: 'workshop',
    country_code: 'IN'
  })
  const [validationErrors, setValidationErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    company_name: '',
    country_code: '',
    contact_number: '',
    services: []
  })
  const [passwordLengthValid, setPasswordLengthValid] = useState(true)

  const validatePasswordLength = (password) => {
    return password.length >= 8
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    if (name === 'password') {
      const isPasswordValid = validatePasswordLength(value)
      setPasswordLengthValid(isPasswordValid)
    }
    setSelectInitState({ ...selectInitState, [name]: value })
    setValidationErrors({ ...validationErrors, [name]: '' })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    showLoader()
    showSnackBar({
      displayMsg: authState?.authResponse
    })
    const data = new FormData(event.currentTarget)
    const formData = {
      first_name: data.get('first_name'),
      last_name: data.get('last_name'),
      email: data.get('email'),
      password: data.get('password'),
      company_name: data.get('company_name'),
      country_code: data.get('country_code'),
      contact_number: Number(data.get('contact_number')),
      services: [data.get('service').toUpperCase()]
    }

    const errors = validationForm(formData)

    if (Object.keys(errors).length === 0) {
      hideLoader()
      authActions.trySignupWithEmail(formData)
    } else {
      hideLoader()
      setValidationErrors(errors)
    }
  }

  function handleKeyPress (event) {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const togglePassword = () => {
    setShowPassword(!showPassword)
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div className='gp-logo gp-mb20'><img alt='HelloParts' height='30px' src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' /></div>
          <Typography component='h1' variant='h5'>
            Sign up
          </Typography>
          <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>Services</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    name='service'
                    value={selectInitState.service}
                    label='Services'
                    onChange={handleChange}
                  >
                    <MenuItem value='workshop'>Workshop</MenuItem>
                    <MenuItem value='insurance'>Insurance</MenuItem>
                    <MenuItem value='parts-dealer'>Parts Dealer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete='first_name'
                  name='first_name'
                  required
                  fullWidth
                  id='first_name'
                  label='First Name'
                  autoFocus
                  onChange={handleChange}
                  value={selectInitState.first_name}
                  error={!!validationErrors.first_name}
                  helperText={validationErrors.first_name || ''}

                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete='last_name'
                  name='last_name'
                  required
                  fullWidth
                  id='last_name'
                  label='Last Name'
                  onChange={handleChange}
                  value={selectInitState.last_name}
                  error={!!validationErrors.last_name}
                  helperText={validationErrors.last_name || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='email'
                  label='Email Address'
                  name='email'
                  autoComplete='email'
                  onChange={handleChange}
                  value={selectInitState.email}
                  error={!!validationErrors.email}
                  helperText={validationErrors.email || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='contact_number'
                  label='Contact No'
                  name='contact_number'
                  autoComplete='contact_number'
                  type='number'
                  onChange={handleChange}
                  value={selectInitState.contact_number}
                  error={!!validationErrors.contact_number}
                  helperText={validationErrors.contact_number || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name='password'
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  id='password'
                  autoComplete='new-password'
                  onChange={handleChange}
                  value={selectInitState.password}
                  error={!!validationErrors.password || !passwordLengthValid}
                  helperText={validationErrors.password || (!passwordLengthValid && 'Password should be atleast 8 character long')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={togglePassword}
                          edge='end'
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id='company_name'
                  label='Company Name'
                  name='company_name'
                  autoComplete='company_name'
                  onChange={handleChange}
                  value={selectInitState.company_name}
                  error={!!validationErrors.company_name}
                  helperText={validationErrors.company_name || ''}
                  onKeyPress={handleKeyPress}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>Country Code</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    name='country_code'
                    value={selectInitState.country_code}
                    label='Country Code'
                    onChange={handleChange}
                    error={!!validationErrors.country_code}
                  >
                    <MenuItem value='IN'>IN</MenuItem>
                    <MenuItem value='DE'>DE</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <Link href='/login' variant='body2'>
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  )
}
