import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CDN_URL } from '../../../constants/siteConstant'
import { Button, Input, TextField } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import './AlwaysAvailable.scss'
import ReCAPTCHA from 'react-google-recaptcha'
import { postData } from '../../../helpers/apiCall'
import { CONTACT_INFO_API } from '../../../constants/Endpoints'
import { validateEmail } from '../../../helpers/Utils'
/* eslint-disable */

const initialContactInfo = { name: '', email: '', info: '' }

const AlwaysAvailable = ({ contactRef }) => {
  const { t } = useTranslation(['alwaysAvailable&Email'])
  const [contactInfo, setContactInfo] = useState(initialContactInfo)
  const [debounceTimeout, setDebounceTimeout] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const recaptchaRef = useRef()
  const [error, setError] = useState({ name: '', email: '', info: '' })

  const [isMobile, setMobile] = useState(
    !window.matchMedia('(min-width: 768px)').matches)

  useEffect(() => {
    window
      .matchMedia('(min-width: 768px)')
      .addEventListener('change', e => !e.matches ? setMobile(true) : setMobile(false))
  }, [])

  useEffect(() => {
    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout)
      }
    }
  }, [])

  const handleContactInfoChange = (event) => {
    const { name, value } = event.target
    setContactInfo(prev => ({
      ...prev,
      [name]: value
    }))
    setError(prev => ({
      ...prev,
      [name]: ''
    }))
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    const newTimeout = setTimeout(() => {
      if (recaptchaRef.current && name === 'email') {
        recaptchaRef.current.reset()
      }
    }, 1000)

    setDebounceTimeout(newTimeout)
  }

  const onChangeCallback = (token) => {
    setContactInfo({ ...contactInfo, recaptcha: token })
  }

  const handleSubmit = async () => {
    
    let newErrors = { name: '', email: '', info: '' }
    if (!contactInfo.name) {
      newErrors.name = t('nameRequired')
    }
    if (!contactInfo.email) {
      newErrors.email = t('emailRequired')
    } else if (!validateEmail(contactInfo.email)) {
      newErrors.email = t('emailInvalid')
    }

    if (!contactInfo.info) {
      newErrors.info = t('informationRequired')
    }
    setError(newErrors)
    setIsLoading(true)
    if (!newErrors.name && !newErrors.email && !newErrors.info) {
      await postData(CONTACT_INFO_API, contactInfo)
      if (contactInfo?.recaptcha) {
        // only reset form if recaptcha was submitted
        setContactInfo(initialContactInfo)
        recaptchaRef.current.reset()
      }
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  return (
    <div className='alwaysAvailableRootContainer d-flex justify-content-center' ref={contactRef}>
      <div
        className='alwaysAvailableSubContainer d-flex justify-content-space-between w-full' style={{
          maxWidth: 1300
        }}
      >
        <div
          className='d-flex flex-d-column' style={{
            maxWidth: '370px'
          }}
        >
          <span className='alwaysAvailableTitleSpan font-Inconsolata font-weight-700 -tracking-04'>{t('alwaysAvailable')}</span>
          <span
            className='font-Manrope gp-text16 font-weight-400 leading-26' style={{
              paddingTop: '25px',
              color: '#919191'
            }}
          >
            {t('alwaysContent')}
          </span>
          <div
            className='d-flex items-center' style={{
              columnGap: '14px',
              paddingTop: '34px'
            }}
          >
            <img
              src={CDN_URL + '/landing-page/icons/fa-phone-green.svg'}
              alt='phone'
              width={14}
              height={14}
            />
            <span className='font-Manrope gp-text18 font-weight-500 leading-28'>
              {t('phoneNumber')}
            </span>
          </div>
          <div
            className='d-flex items-center' style={{
              columnGap: '14px',
              paddingTop: '12px',
              borderBottom: '2px solid #EBEAED',
              paddingBottom: '49px'
            }}
          >
            <img
              src={CDN_URL + '/landing-page/icons/fa-envelope-green.svg'}
              alt='phone'
              width={14}
              height={14}
            />
            <span className='font-Manrope gp-font18 font-weight-500 leading-28'>
              {t('gopartsEmail')}
            </span>
          </div>
          <span
            className='font-Inconsolata gp-text22 font-weight-500 leading-32' style={{
              paddingTop: '45px'
            }}
          >
            {t('inPerson')}
          </span>
          <span
            className='font-Manrope gp-text16 font-weight-400 leading-26' style={{
              paddingTop: '12px',
              fontStyle: 'normal',
              color: '#919191'
            }}
          >
            {t('companyName')}
          </span>
          <span
            className='font-Manrope gp-text16 font-weight-400 leading-26' style={{
              fontStyle: 'normal',
              color: '#919191'
            }}
          >
            {t('address')}
          </span>
          <img
            style={{
              marginTop: 42,
              borderRadius: '10px'
            }}
            src={CDN_URL + '/landing-page/location.png'}
            alt='phone'
            width={isMobile ? 311 : 370}
            height={230}
          />
        </div>
        <div
          className='d-flex flex-d-column' style={{
            maxWidth: '470px'
          }}
        >
          <span className='font-Inconsolata alwaysAvailableTitleSpan font-weight-700 -tracking-04'>{t('alsoByEmail')}</span>
          <span
            className='font-Manrope gp-text16 font-weight-400 leading-26' style={{
              paddingTop: '25px',
              color: '#919191'
            }}
          >
            {t('alsoByEmailContent')}
          </span>
          <div
            className='alwaysAvailableFormContainer d-flex flex-d-column' style={{
              padding: '20px 50px 20px 50px',
              backgroundColor: 'rgba(235, 234, 237, 0.3)',
              borderRadius: '10px'
            }}
          >
            <span
              className='uppercase required font-Manrope gp-text14 font-weight-700 leading-26 tracking-2 gp-mb8'
            >
              {t('name')}
            </span>
            <Input
              disableUnderline
              name='name'
              value={contactInfo.name}
              style={{
                display: 'flex',
                backgroundColor: 'white',
                border: error.name ? '1px solid red' : '2px solid #EBEAED',
                padding: '12px 0px 12px 24px',
                borderRadius: '100px',
                fontFamily: 'DMSans',
                fontWeight: 500,
                lineHeight: '26px',
                fontSize: '14px'
              }}
              placeholder={t('namePlaceholder')}
              onChange={handleContactInfoChange}
            />
            {error.name && <div className='error-text fs_12 gp-mt4'>{error.name}</div>}
            <span className='uppercase required font-Manrope gp-text14 font-weight-700 leading-26 tracking-2 gp-mb8 gp-mt20'>
              {t('email')}
            </span>
            <Input
              disableUnderline
              name='email'
              value={contactInfo.email}
              style={{
                backgroundColor: 'white',
                border: error.email ? '1px solid red' : '2px solid #EBEAED',
                padding: '12px 0px 12px 24px',
                borderRadius: '100px',
                fontFamily: 'DMSans',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '26px',
              }}
              placeholder={t('emailPlaceholder')}
              onChange={handleContactInfoChange}
            />
            {error.email && <div className='error-text fs_12 gp-mt4'>{error.email}</div>}
            <span
              className='uppercase required font-Manrope gp-text14 font-weight-700 leading-26 tracking-2 gp-mb8 gp-mt20'
            >
              {t('news')}
            </span>
            <TextField
              multiline
              name='info'
              value={contactInfo.info}
              placeholder={t('newsPlaceHolder')}
              rows={3}
              style={{
                backgroundColor: 'white',
                border: error.info ? '1px solid red' : '1px solid #EBEAED',
                borderRadius: '10px',
                marginBottom: '20px',
                fontSize: '14px'
              }}
              onChange={handleContactInfoChange}
            />
            {error.info && <div style={{
              marginTop: -16
            }} className='error-text fs_12 gp-mb20'>{error.info}</div>}
            <div className='gp-mt8 gp-mb20'>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={onChangeCallback}
                ref={recaptchaRef}
              />
            </div>
            <div className='tr'>
              <Button
                style={{
                  fontFamily: 'DMSans',
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: 500,
                  lineHeight: '26px',
                  backgroundColor: '#6926D9',
                  borderRadius: '100px',
                  padding: '12px',
                  width: '100px',
                  textTransform: 'none'
                }}
                onClick={handleSubmit}
              >{isLoading ? <CircularProgress className='custom-circular-loader' /> : t('send')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlwaysAvailable
