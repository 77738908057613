export const SINGUP_IN_PROGRESS = 'SIGNUP_IN_PROGRESS'
export const SINGUP_COMPLETED = 'SINGUP_COMPLETED'
export const SINGUP_ERROR = 'SINGUP_ERROR'
export const SINGUP_ERROR_FIXED = 'SINGUP_ERROR_FIXED'
export const LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS'
export const LOGIN_COMPLETE = 'LOGIN_CALL_COMPLETE'
export const LOGIN_IN_ERROR = 'LOGIN_IN_ERROR'
export const LOGIN_IN_ERROR_FIXED = 'LOGIN_IN_ERROR_FIXED'
export const LOGOUT_COMPLETED = 'LOGOUT_COMPLETED'
export const DASHBOARD_IN_PROGRESS = 'DASHBOARD_IN_PROGRESS'
export const SHOW_DASHBOARD = 'SHOW_DASHBOARD'
export const OPEN_SERVICES = 'OPEN_SERVICES'
export const OPEN_PARTS_CHECKER = 'OPEN_PARTS_CHECKER'
export const OPEN_PARTS_IDENTIFIER = 'OPEN_PARTS_IDENTIFIER'
export const OPEN_VEHICLE_LINKER = 'OPEN_VEHICLE_LINKER'
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'

export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR'

export const DMS_LIST = 'DMS_LIST'
export const DMS_LIST_ERROR = 'DMS_LIST_ERROR'

export const UPDATE_LEAD_DMS = 'UPDATE_LEAD_DMS'
export const UPDATE_LEAD_DMS_ERROR = 'UPDATE_LEAD_DMS_ERROR'
