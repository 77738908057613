import axios from 'axios'
import { SUCCESS_CODE } from '../constants/siteConstant'
import { showSnackBar } from './Utils'
axios.defaults.withCredentials = true

const headers = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export const getData = (endpoint) => {
  return axios
    .get(endpoint)
    .then((res) => {
      return res.data
    }).catch(error => {
      showSnackBar({ message: error?.response?.data?.message || error?.message, severity: 'error' })
      return error?.response?.data
    })
}

export const postData = (endpoint, data) => {
  return axios
    .post(endpoint, data, headers)
    .then((res) => {
      if (res.status === SUCCESS_CODE) {
        showSnackBar({ message: res?.data?.message, severity: 'success' })
        return res.data
      }
    }).catch(error => {
      showSnackBar({ message: error?.response?.data?.message || error.response?.message || error?.response?.data?.error || error?.message, severity: 'error' })
      return error.response
    })
}

export const putData = (endpoint, data) => {
  return axios
    .put(endpoint, data, headers)
    .then((res) => {
      if (res.status === SUCCESS_CODE) {
        return res.data
      }
    }).catch(error => {
      return error?.response?.data?.message || error?.response
    })
}
