import { DMS_LIST_API, LEAD_API } from '../constants/Endpoints'
import { DMS_LIST, DMS_LIST_ERROR, UPDATE_EMAIL, UPDATE_EMAIL_ERROR, UPDATE_LEAD_DMS, UPDATE_LEAD_DMS_ERROR } from '../constants/actionTypes'
import { SUCCESS_CODE } from '../constants/siteConstant'
import { getData, putData } from '../helpers/apiCall'

export function updateEmail (data) {
  return { type: UPDATE_EMAIL, data }
}

export function updateLeadEmailError (data) {
  return { type: UPDATE_EMAIL_ERROR, data }
}

export function getDMSList (data) {
  return { type: DMS_LIST, data }
}

export function getDMSListError (data) {
  return { type: DMS_LIST_ERROR, data }
}

export function putLeadDMS (data) {
  return { type: UPDATE_LEAD_DMS, data }
}

export function putLeadDMSError (data) {
  return { type: UPDATE_LEAD_DMS_ERROR, data }
}

export async function updateLeadEmail (data) {
  const response = await putData(LEAD_API, data)
    .then(jsonResponse => {
      if (jsonResponse?.code === SUCCESS_CODE) {
        return updateEmail(jsonResponse)
      } else {
        return updateLeadEmailError(jsonResponse.data)
      }
    })
  return response
}

export async function fetchDMSList () {
  const response = await getData(DMS_LIST_API)
    .then(jsonResponse => {
      if (jsonResponse?.code === SUCCESS_CODE) {
        return getDMSList(jsonResponse)
      } else {
        return getDMSListError(jsonResponse.data)
      }
    })
  return response
}

export async function updateLeadDMS (leadId, data) {
  const response = await putData(LEAD_API + `/${leadId}`, data)
    .then(jsonResponse => {
      if (jsonResponse?.code === SUCCESS_CODE) {
        return putLeadDMS(jsonResponse)
      } else {
        return putLeadDMSError(jsonResponse.data)
      }
    })
  return response
}
