import * as endpoints from '../constants/Endpoints'
import axios from 'axios'
import { showSnackBar } from '../helpers/Utils'

const headers = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export async function getPDList () {
  const endPoint = `${endpoints.PD_LIST_API}`
  try {
    const res = await axios.get(endPoint, { headers })
    return res.data?.data
  } catch (error) {
    showSnackBar({ displayMsg: error?.response?.data?.message })
  }
}

export async function trySigningUp (data) {
  const endPoint = `${endpoints.SIGNUP_API}`
  try {
    const res = await axios.post(endPoint, (data), { headers })
    return res.data?.data
  } catch (error) {
    showSnackBar({ displayMsg: { error: (error?.response?.data?.message || error?.response?.data?.error || error?.message) } })
  }
}

export async function trySavingLead (leadId, workshopId, data) {
  const endPoint = `${endpoints.SAVE_LEAD_API}/${leadId}/workshop/${workshopId}/details`
  try {
    const res = await axios.put(endPoint, data, { headers })
    return res.data?.data
  } catch (error) {
    showSnackBar({ displayMsg: { error: (error?.response?.data?.message || error?.response?.data?.error || error?.message) } })
  }
}

export async function tryCustomRegistration (data) {
  const endPoint = `${endpoints.LEAD_API}`
  try {
    const res = await axios.post(endPoint, (data), { headers })
    return res.data?.data
  } catch (error) {
    showSnackBar({ displayMsg: { error: (error?.response?.data?.message || error?.response?.data?.error || error?.message) } })
  }
}
