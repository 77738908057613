import { DASHBOARD_IN_PROGRESS, SHOW_DASHBOARD } from '../constants/actionTypes'
import { USER_RESPONSE_KEY } from '../constants/siteConstant'

export function dashboardInProgress (data) {
  return { type: DASHBOARD_IN_PROGRESS, data }
}

export function showDashboard (data) {
  return { type: SHOW_DASHBOARD, data }
}

export function displayServicesOnDashboard () {
  const responseData = JSON.parse(window.localStorage.getItem(USER_RESPONSE_KEY)) || {}
  return function (dispatch) {
    dispatch(showDashboard(responseData))
  }
}
