export function showLoader () {
  const evt = new CustomEvent('showLoader', {})
  window.dispatchEvent(evt)
}

export function hideLoader () {
  const evt = new CustomEvent('hideLoader', {})
  window.dispatchEvent(evt)
}

export function showSnackBar (data) {
  const evt = new CustomEvent('showSnackBar', { detail: data })
  window.dispatchEvent(evt)
}

export function hideSnackBar () {
  const evt = new CustomEvent('hideSnackBar', {})
  window.dispatchEvent(evt)
}

export function displayMsgType (msgType) {
  if (msgType?.error || msgType?.severity === 'error') {
    return 'error'
  }
  if (msgType.success || msgType?.message) {
    return 'success'
  }
  return 'info'
}

export function validationForm (formData) {
  const errors = {}
  if (!formData.first_name.trim()) {
    errors.first_name = 'First name is required'
  }
  if (!formData.last_name.trim()) {
    errors.last_name = 'Last name is required'
  }
  if (!formData.email.trim()) {
    errors.email = 'Email is required'
  }
  if (!formData.password.trim()) {
    errors.password = 'Password is required'
  }
  if (!formData.company_name.trim()) {
    errors.company_name = 'Company Name is required'
  }
  if (!formData.country_code.trim()) {
    errors.country_code = 'Country Code is required'
  }
  if (!formData.contact_number) {
    errors.contact_number = 'Contact Number is required'
  }
  if (!formData.services) {
    errors.services = 'Services is required'
  }

  return errors
}

export function pushDataInLocalStorage (key, responseData) {
  window.localStorage.setItem(key, JSON.stringify(responseData))
}

export function fetchDataFromLocalStorage (requestKey) {
  window.localStorage.getItem(requestKey)
}

export function getParameterByName (name, url) {
  /* eslint-disable */
  if (!url) url = window.location.href
  name = name.replace(/[\[\]]/g, '\\$&')// This is just to avoid case sensitiveness for query parameter name
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2])
}

export const CookiesHelper = {
  /* eslint-disable */
  getItem: function (sKey) {
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            '(?:(?:^|.*;)\\s*' +
            encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
            '\\s*\\=\\s*([^;]*).*$)|^.*$'
          ),
          '$1'
        )
      ) || null
    )
  },
  setItem: function (sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) {
      return false
    }
    let sExpires = ''
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires =
            vEnd === Infinity
              ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
              : '; max-age=' + vEnd
          break
        case String:
          sExpires = '; expires=' + vEnd
          break
        case Date:
          sExpires = '; expires=' + vEnd.toUTCString()
          break
      }
    }
    document.cookie =
      encodeURIComponent(sKey) +
      '=' +
      encodeURIComponent(sValue) +
      sExpires +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '') +
      (bSecure ? '; secure' : '')
    return true
  },
  removeItem: function (sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey)) {
      return false
    }
    document.cookie =
      encodeURIComponent(sKey) +
      '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
      (sDomain ? '; domain=' + sDomain : '') +
      (sPath ? '; path=' + sPath : '')
    return true
  },
  hasItem: function (sKey) {
    return new RegExp(
      '(?:^|;\\s*)' +
      encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
      '\\s*\\='
    ).test(document.cookie)
  },
  keys: /* optional method: you can safely remove it! */ function () {
    const aKeys = document.cookie
      .replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, '')
      .split(/\s*(?:\=[^;]*)?;\s*/)
    for (let nIdx = 0; nIdx < aKeys.length; nIdx++) {
      aKeys[nIdx] = decodeURIComponent(aKeys[nIdx])
    }
    return aKeys
  }
}

export const goToFunnelPage = () => {
  const domain = window.location.origin
  let relativePath = '/view-request?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhc2NoYS5nb3VkZW1vbmRAZ29wYXJ0cy50ZWNoIiwibGVhZElkIjoxMSwid29ya3Nob3BfYWNjb3VudF9uYW1lIjoiU2FzY2hhICIsImlhdCI6MTcxNTA3MjY0MCwiZXhwIjoxNzE3NjY0NjQwfQ.qUC6jkZvP87iCdDlrjrgqBZOsY4lXyuSd9WtDtEZChs'
  const url = domain + relativePath
  window.open(url, '_blank')
}

export const goToHomePage = () => {
  window.location.href = window.location.origin
}

export function validateEmail(email) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}