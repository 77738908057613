import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as commonEnglishFile from '../src/locales/en.json'
import * as commonGermanFile from '../src/locales/de.json'

const getItemFromCookies = (sKey) => {
  /* eslint-disable */
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          '(?:(?:^|.*;)\\s*' +
          encodeURIComponent(sKey).replace(/[\-\.\+\*]/g, '\\$&') +
          '\\s*\\=\\s*([^;]*).*$)|^.*$'
        ),
        '$1'
      )
    ) || null
  )
}

const savedLanguage = getItemFromCookies('defaultLanguage') || 'de'
i18n
  .use(initReactI18next) 
  .init({
    resources: {
      en: commonEnglishFile,
      de: commonGermanFile
    },
    lng: savedLanguage || 'de', 
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  })

export default i18n
