import React from 'react'
import { CDN_URL } from '../../../constants/siteConstant'
import AdvantagesSection from '../AdvantagesSection/AdvantagesSection'
import { useTranslation } from 'react-i18next'
import './AdvantagesHelloparts.scss'

const AdvantagesHelloparts = ({ advantageRef }) => {
  const { t } = useTranslation(['advantagesHelloParts'])

  return (
    <div className='advContainer d-flex flex-d-column items-center justify-content-center' ref={advantageRef}>
      <span
        className='advTitleSpan font-Inconsolata font-weight-700 d-flex -tracking-04 tc'
      >
        {t('advantagesTitle')}
      </span>
      <div
        className='d-flex advSectionRootContainer'
        style={{
          maxWidth: 1300,
          columnGap: '32px'
        }}
      >
        <AdvantagesSection
          image={{
            src: CDN_URL + '/landing-page/icons/advantage_1.svg',
            alt: 'Optimization',
            width: 126,
            height: 122
          }}
          // spanmt='-22px'
          title={t('optimizationTitle')}
          content={t('optimizationContent')}
        />
        <AdvantagesSection
          image={{
            src: CDN_URL + '/landing-page/icons/advantage_2.svg',
            alt: 'Time Savings',
            width: 126,
            height: 122
          }}
          // spanmt='-58px'
          title={t('seamlessIntegrationTitle')}
          content={t('seamlessIntegrationContent')}
        />
        <AdvantagesSection
          image={{
            src: CDN_URL + '/landing-page/icons/advantage_3.png',
            alt: 'Efficient Management',
            width: 126,
            height: 122
          }}
          // spanmt='-152px'
          title={t('efficientManagementTitle')}
          content={t('efficientManagementContent')}
        />
      </div>
    </div>
  )
}

export default AdvantagesHelloparts
