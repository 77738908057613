import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import ThunkMiddleware from 'redux-thunk'
import mainReducer from '../reducers/index'

export default function configureStore (initialState) {
  const store = createStore(
    mainReducer,
    applyMiddleware(ThunkMiddleware),
    initialState
  )
  return store
}
