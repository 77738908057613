import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import configureStore from './store/configureStore'
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))
const store = configureStore()
window.store = store
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
