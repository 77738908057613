import React from 'react'
import { CDN_URL } from '../../constants/siteConstant'
import { useTranslation } from 'react-i18next'

export default function LeftStaticScreen () {
  const { t } = useTranslation(['auth'])

  return (

    <div className='left-container'>
      <div
        className='h-full bg-cover vertical-center flex-d-column bg-image' style={{
          backgroundImage: `url(${CDN_URL + '/landing-page/helloparts_hero_full.png'
              })`
        }}
      >
        <div className='left-wrapper'>
          <div
            className='d-flex items-center flex-d-column title-cont welcome-text-wrapper'
          >
            <span className='font-Manrope gp-text28 gp-pt40 font-weight-700 d-flex text-white leading-52 -tracking-04'>
              {t('welcomeToHelloParts')}
            </span>
            <span className='font-Manrope gp-text20 font-weight-400 leading-26 text-white gp-pt4'>
              {t('partsMadeEasy')}
            </span>
          </div>
          <div
            className='d-flex items-center flex-d-column title-cont lead-container'
          >
            <span className='font-Inconsolata font-weight-700 d-flex text-white leading-52 -tracking-04'>
              {t('pageTitle')}
            </span>
            <span className='font-Manrope gp-text16 font-weight-400 leading-26 text-white gp-pt16 page-subtitle'>
              {t('pageSubtitle')}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
