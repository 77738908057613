import { Button } from '@mui/material'
import React from 'react'
import './MiniBanner.scss'
import { CDN_URL } from '../../../constants/siteConstant'
import { useNavigate } from 'react-router-dom'

const MiniBanner = ({ title, buttonText }) => {
  const navigate = useNavigate()
  const goToRegisterPage = () => {
    navigate('/register')
  }

  return (
    <div
      className='bannerContainer d-flex justify-content-center' style={{ backgroundImage: `url(${CDN_URL}/landing-page/product-bg-image.svg)` }}
    >
      <div
        className='bannerSubContainer d-flex justify-content-space-between self-center items-center w-full' style={{
          maxWidth: 1300
        }}
      >
        <span className='font-Inconsolata font-weight-500 gp-text22 leading-32 text-white text-center'>{title}</span>
        <Button
          className='action-btn'
          onClick={goToRegisterPage}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}

export default MiniBanner
