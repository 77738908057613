import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { displayMsgType } from '../../helpers/Utils'

const Alert = React.forwardRef(function Alert (
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export default function SnackBarComponent (props) {
  const { handleClose, displayMsg, open } = props

  if (displayMsg?.message || displayMsg?.error || displayMsg?.success) {
    return (
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={handleClose} severity={displayMsgType(displayMsg)} sx={{ width: '100%' }}>
          {displayMsg?.message || displayMsg?.error || displayMsg?.success}
        </Alert>
      </Snackbar>
    )
  }

  return null
}
