import * as React from 'react'
import { styled } from '@mui/material/styles'
import MuiAccordion from '@mui/material/Accordion'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: '2px solid #EBEAED'
  },
  '&::before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  backgroundColor: 'white',
  flexDirection: 'row-reverse',
  padding: '8px 0px 8px 0px',
  alignItems: 'center',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  }
}))

export default function AccordionWrapper ({ title1, title2, title3, title4, title5, title6, subTitle1, subTitle2, subTitle3, subTitle4, subTitle5, subTitle6 }) {
  const [expanded, setExpanded] = React.useState('panel1')

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <span className='font-Manrope font-weight-700 gp-text14 leading-26 tracking-2 uppercase gp-ml8'>
            {title1}
          </span>
        </AccordionSummary>
        <MuiAccordionDetails style={{ padding: '0px 0px 30px 0px' }}>
          <span className='font-weight-400 gp-text16 leading-26 font-Manrope' style={{ color: '#919191' }}>
            {subTitle1}
          </span>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls='panel2d-content' id='panel2d-header'>
          <span className='font-Manrope font-weight-700 gp-text14 leading-26 tracking-2 uppercase gp-ml8'>
            {title2}
          </span>
        </AccordionSummary>
        <MuiAccordionDetails style={{ padding: '0px 0px 30px 0px' }}>
          <span className='font-weight-400 gp-text16 leading-26 font-Manrope' style={{ color: '#919191' }}>
            {subTitle2}
          </span>
        </MuiAccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
          <span className='font-Manrope font-weight-700 gp-text14 leading-26 tracking-2 uppercase gp-ml8'>
            {title3}
          </span>
        </AccordionSummary>
        <MuiAccordionDetails style={{ padding: '0px 0px 30px 0px' }}>
          <span className='font-weight-400 gp-text16 leading-26 font-Manrope' style={{ color: '#919191' }}>
            {subTitle3}
          </span>
        </MuiAccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
          <span className='font-Manrope font-weight-700 gp-text14 leading-26 tracking-2 uppercase gp-ml8'>
            {title4}
          </span>
        </AccordionSummary>
        <MuiAccordionDetails style={{ padding: '0px 0px 30px 0px' }}>
          <span className='font-weight-400 gp-text16 leading-26 font-Manrope' style={{ color: '#919191' }}>
            {subTitle4}
          </span>
        </MuiAccordionDetails>
      </Accordion>

      {title5 &&
        <>
          <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
            <AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
              <span className='font-Manrope font-weight-700 gp-text14 leading-26 tracking-2 uppercase gp-ml8'>
                {title5}
              </span>
            </AccordionSummary>
            <MuiAccordionDetails style={{ padding: '0px 0px 30px 0px' }}>
              <span className='font-weight-400 gp-text16 leading-26 font-Manrope' style={{ color: '#919191' }}>
                {subTitle5}
              </span>
            </MuiAccordionDetails>
          </Accordion>

          <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary aria-controls='panel3d-content' id='panel3d-header'>
              <span className='font-Manrope font-weight-700 gp-text14 leading-26 tracking-2 uppercase gp-ml8'>
                {title6}
              </span>
            </AccordionSummary>
            <MuiAccordionDetails style={{ padding: '0px 0px 30px 0px' }}>
              <span className='font-weight-400 gp-text16 leading-26 font-Manrope' style={{ color: '#919191' }}>
                {subTitle6}
              </span>
            </MuiAccordionDetails>
          </Accordion>
        </>}
    </div>
  )
}
