import React from 'react'

const ProductFeaturesCard = ({ cardTitle, cardContent }) => {
  return (
    <div className='d-flex flex-d-column items-center' style={{ maxWidth: '370px' }}>
      <span className='font-Inconsolata font-weight-500 gp-text22 leading-32' style={{ textAlign: 'left', width: '354px' }}>
        {cardTitle}
      </span>
      <span className='font-Manrope font-weight-400 gp-text16 leading-26 card-content'>
        {cardContent}
      </span>
    </div>
  )
}

export default ProductFeaturesCard
