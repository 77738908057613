import { LOGIN_API, SIGNUP_API, LOGOUT_API, DASHBOARD_URL, RESET_PASSWORD_URL } from '../constants/Endpoints'
import {
  SINGUP_IN_PROGRESS, SINGUP_COMPLETED,
  SINGUP_ERROR, SINGUP_ERROR_FIXED, LOGIN_IN_PROGRESS,
  LOGIN_COMPLETE, LOGIN_IN_ERROR, LOGIN_IN_ERROR_FIXED,
  LOGOUT_COMPLETED, FORGOT_PASSWORD, FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD, RESET_PASSWORD_ERROR
} from '../constants/actionTypes'
import { SUCCESS_CODE, USER_RESPONSE_KEY, WORKSHOP_CONST } from '../constants/siteConstant'
import { getParameterByName, pushDataInLocalStorage } from '../helpers/Utils'
import { postData } from '../helpers/apiCall'

export function signupInProgress (data) {
  return { type: SINGUP_IN_PROGRESS, data }
}

export function signupCompleted (data) {
  return { type: SINGUP_COMPLETED, data }
}

export function signupError (data) {
  return { type: SINGUP_ERROR, data }
}

export function signupErrorFixed (data) {
  return { type: SINGUP_ERROR_FIXED, data }
}

export function loginInProgress (data) {
  return { type: LOGIN_IN_PROGRESS, data }
}

export function loginCompleted (data) {
  return { type: LOGIN_COMPLETE, data }
}

export function loginError (data) {
  return { type: LOGIN_IN_ERROR, data }
}

export function loginErrorFixed (data) {
  return { type: LOGIN_IN_ERROR_FIXED, data }
}

export function logoutCompleted (data) {
  return { type: LOGOUT_COMPLETED, data }
}

export function forgotPassword (data) {
  return { type: FORGOT_PASSWORD, data }
}

export function forgotPasswordError (data) {
  return { type: FORGOT_PASSWORD_ERROR, data }
}

export function resetPassword (data) {
  return { type: RESET_PASSWORD, data }
}

export function resetPasswordError (data) {
  return { type: RESET_PASSWORD_ERROR, data }
}

export function trySignupWithEmail (data) {
  return function (dispatch) {
    dispatch(signupInProgress())
    postData(SIGNUP_API, data)
      .then(jsonResponse => {
        if (jsonResponse.code === SUCCESS_CODE) {
          window.location.pathname = DASHBOARD_URL
          pushDataInLocalStorage(USER_RESPONSE_KEY, jsonResponse.data)
          dispatch(signupCompleted(jsonResponse))
        } else {
          dispatch(signupError(jsonResponse.data))
        }
      })
  }
}

function checkForService (services) {
  const redirectUrl = getParameterByName('redirectUrl')
  if (redirectUrl) {
    return redirectUrl
  }
  if (services?.length === 1 && services[0]?.name === WORKSHOP_CONST) {
    return services[0]?.url
  } else {
    return DASHBOARD_URL
  }
}

function redirectToResetPassword () {
  const reference = window?.location?.href
  window.location.href = `${RESET_PASSWORD_URL}?redirectUrl=` + reference
}

export function tryLoginWithEmail (data) {
  return function (dispatch) {
    dispatch(loginInProgress())
    postData(LOGIN_API, data)
      .then(jsonResponse => {
        if (jsonResponse.code === SUCCESS_CODE) {
          if (jsonResponse?.data?.status === 2 || jsonResponse?.data?.status === 3) {
            redirectToResetPassword()
          } else {
            window.location.href = checkForService(jsonResponse?.data?.services)
            pushDataInLocalStorage(USER_RESPONSE_KEY, jsonResponse.data)
            dispatch(loginCompleted(jsonResponse))
          }
        } else {
          dispatch(loginError(jsonResponse.data))
        }
      })
  }
}

export function tryLogout () {
  return function (dispatch) {
    postData(LOGOUT_API)
      .then(jsonResponse => {
        if (jsonResponse.code === SUCCESS_CODE) {
          window.localStorage.removeItem(USER_RESPONSE_KEY)
          dispatch(logoutCompleted(jsonResponse))
        } else {
          dispatch(loginError(jsonResponse.data))
        }
      })
  }
}
