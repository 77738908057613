import { DASHBOARD_INITIAL_STATE } from '../constants/initialState'
import { DASHBOARD_IN_PROGRESS, SHOW_DASHBOARD } from '../constants/actionTypes'

export default function dashboardReducer (state = DASHBOARD_INITIAL_STATE, action) {
  switch (action.type) {
    case DASHBOARD_IN_PROGRESS:
      return Object.assign({}, state, {
        isDashboardShowing: true
      })

    case SHOW_DASHBOARD:
      return Object.assign({}, state, {
        isDashboardShowing: false,
        showDashboardPage: true,
        dashboardData: action?.data
      })
    default:
      return state
  }
}
