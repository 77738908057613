import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { CDN_URL } from '../../../constants/siteConstant'
import { Button } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import './Header.scss'

const NavManu = styled.ul`
  list-style: none;
  display: flex;
  padding-left: 5%px;
  justify-content: space-between;
  width: 80%;
  li:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-style: solid;
    text-underline-offset: 5px;
    text-decoration-color: #6925d8;
  }
  @media screen and (max-width: 768px) {
    display: ${(props) => (props.istoggleopen ? 'flex' : 'none')};
    flex-direction: column;
    padding-left:0px;
    align-items: start;
    row-gap: 20px;
    width: 100%;
    margin-top: 5px;
  }
`

const Header = ({ scrollToSection, scrollToAdvantageSection, scrollToContact }) => {
  const [isToggleOpen, setIsToggleOpen] = useState(false)
  const { t } = useTranslation(['landinghero'])
  const navigate = useNavigate()

  const handleToggleOpen = useCallback(() => {
    setIsToggleOpen(!isToggleOpen)
  }, [isToggleOpen])

  const goToHomePage = () => {
    navigate('/')
  }

  const goToRegisterPage = () => {
    navigate('/register')
  }

  return (
    <>
      <header className='header'>
        <div className='nav_logo'>
          <img
            className='logoImage cp'
            src={CDN_URL + '/landing-page/helloparts_logo_black.png'}
            alt='Helloparts logo'
            width={227}
            height={46}
            onClick={goToHomePage}
          />
        </div>

        <NavManu className='gap-x-28 items-center' istoggleopen={isToggleOpen ? isToggleOpen.toString() : undefined}>
          <li onClick={scrollToAdvantageSection}>
            <span className='font-Manrope gp-text18 font-weight-300 leading-26 cp'>{t('advantage')}</span>
          </li>
          <li onClick={scrollToSection}>
            <span className='font-Manrope gp-text18 font-weight-300 leading-26 cp'>{t('functions')}</span>
          </li>
          <li onClick={scrollToContact} style={{ marginRight: '300px' }}>
            <span className='font-Manrope gp-text18 font-weight-300 leading-26 cp'>{t('contact')}</span>
          </li>
          <li className='loginLink'>
            <a className='clr_202020' href='/login'>
              <span className='font-Manrope gp-text18 font-weight-300 leading-26'>
                {t('login')}
              </span>
            </a>
          </li>
          <li>
            <Button
              style={{
                backgroundColor: '#6926D9'
              }}
              sx={{
                borderRadius: '100px',
                textTransform: 'none',
                color: 'white',
                paddingLeft: '18px',
                paddingRight: '18px',
                paddingTop: '7px',
                paddingBottom: '7px',
                marginLeft: {
                  xxs: '0px',
                  sm: '4px'
                }
              }}
              onClick={goToRegisterPage}
            >
              <span className='font-Manrope gp-text18 font-weight-500 leading-26 text-white w-150'>
                {t('signUp')}
              </span>
            </Button>
          </li>
        </NavManu>
        <MenuIcon className='menuToggleBtn cp-theme-color' onClick={handleToggleOpen} />
      </header>
    </>
  )
}

export default Header
