import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FooterLinksList from '../FooterLinksList'
import './Footer.scss'

const Footer = ({ scrollToSection, scrollToAdvantageSection, scrollToContact, scrollToTopOfPage }) => {
  const { t } = useTranslation(['footer'])

  const goToPrivacyPage = () => {
    window.open('https://goparts.tech/privacy-policy', '_blank')
  }

  const goToImprintPage = () => {
    window.open('https://goparts.tech/impressum', '_blank')
  }

  return (
    <div
      className='footerRootContainer d-flex justify-content-space-between items-start'
      style={{
        backgroundColor: 'black'
      }}
    >
      <div
        className='d-flex flex-d-column'
        style={{
          maxWidth: '360px'
        }}
      >
        <span className='text-white font-Inconsolata gp-text22 font-weight-500 leading-32'>
          {t('workshopsDealers')}
        </span>
        <span
          className='text-white font-Manrope gp-text16 font-weight-400 leading-26'
          style={{
            paddingTop: '32px'
          }}
        >
          {t('oneStopShop')}
        </span>
        <div
          className='footerEmailButtonContainer w-full d-flex items-center'
          style={{
            marginTop: '42px',
            columnGap: '10px'
          }}
        >
          <Button
            className='action-btn footer-btn'
            onClick={scrollToContact}
          >{t('register')}
          </Button>
        </div>
        <div
          className='d-flex footerImprintContainer' style={{
            columnGap: '57px'
          }}
        >
          <span className='text-white font-Manrope gp-text16 font-weight-400 leading-26 cp footer-item' onClick={goToPrivacyPage}>
            {t('dataProtection')}
          </span>
          <span className='text-white font-Manrope gp-text16 font-weight-400 leading-26 cp footer-item' onClick={goToImprintPage}>
            {t('imprint')}
          </span>
        </div>
      </div>
      <div className='footerLinksDemoContianer justify-content-space-between w-full'>
        <FooterLinksList
          scrollToSection={scrollToSection}
          scrollToAdvantageSection={scrollToAdvantageSection}
          scrollToContact={scrollToContact}
          scrollToTopOfPage={scrollToTopOfPage}
          title={t('overview')}
          list={[t('home'),
            t('advantage'),
            t('functions'),
            t('contact')]}
        />
        <div
          className='d-flex flex-d-column' style={{
            rowGap: '12px',
            alignItems: 'center'
          }}
        />
      </div>
    </div>
  )
}

export default Footer
