import React from 'react'
import styled from 'styled-components'
import './AdvantagesSection.scss'

const SpanInconsolata = styled('span')({
  fontFamily: 'Inconsolata',
  fontWeight: 500,
  fontSize: '22px',
  display: 'flex',
  lineHeight: '32px',
  marginTop: '30px'
})

const SpanManrope = styled('span')({
  fontWeight: 400,
  fontFamily: 'Manrope',
  fontSize: '16px',
  lineHeight: '26px',
  color: '#919191',
  display: 'flex',
  paddingTop: '24px'
})

const AdvantagesSection = ({
  image,
  spanmt,
  title,
  content
}) => {
  return (
    <div
      className='advSectionContainer'
    >
      <img
        src={image.src}
        alt={image.alt}
        width={image.width}
        height={image.height}
        style={{
          marginLeft: image?.ml
        }}
      />
      <SpanInconsolata style={{ marginTop: spanmt }}>
        {title}
      </SpanInconsolata>
      <SpanManrope>{content}</SpanManrope>
    </div>
  )
}

export default AdvantagesSection
