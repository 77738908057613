import axios from 'axios'
import { FORGOT_PASSWORD_API, RESET_PASSWORD_API, RESET_PASSWORD_API_FOR_NEW_EXPIRE_USER } from '../constants/Endpoints'
import { showSnackBar } from '../helpers/Utils'

const headers = {
  headers: {
    'Content-Type': 'application/json'
  }
}

export async function tryForgettingPassword (emailId) {
  try {
    const res = await axios.post(FORGOT_PASSWORD_API, { email: emailId }, { headers })
    showSnackBar({ displayMsg: { success: res.data.message } })
    return res.data
  } catch (error) {
    showSnackBar({ displayMsg: { error: (error?.response?.data?.message || error?.response?.data?.error || error?.message) } })
  }
}

export async function tryResettingPassword (token, password, confirmPassword) {
  try {
    const res = await axios.post(RESET_PASSWORD_API, { token, password, confirm_password: confirmPassword }, { headers })
    showSnackBar({ displayMsg: { success: res.data.message } })
    return res.data
  } catch (error) {
    showSnackBar({ displayMsg: { error: (error?.response?.data?.message || error?.response?.data?.error || error?.message) } })
  }
}

export async function tryResettingPasswordForNewAndExpiredUser (password, confirmPassword) {
  try {
    const res = await axios.post(RESET_PASSWORD_API_FOR_NEW_EXPIRE_USER, { password, confirm_password: confirmPassword }, { headers })
    showSnackBar({ displayMsg: { success: res.data.message } })
    return res.data
  } catch (error) {
    showSnackBar({ displayMsg: { error: (error?.response?.data?.message || error?.response?.data?.error || error?.message) } })
  }
}
