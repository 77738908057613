import React, { useEffect, useState } from 'react'
import '../../assets/styleSheets/header.scss'
import { Box, Button, Card, CardContent, Container, CssBaseline, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CookiesHelper, goToHomePage, showSnackBar } from '../../helpers/Utils'
import { AccountCircle } from '@mui/icons-material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'
import Select from '@mui/material/Select'

const defaultTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#CEF2F1',
          width: '100%'
        }
      }
    }
  }
})

function Dashboard (props) {
  const { dashboardActions, authActions, dashboardState, authState } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const { t } = useTranslation(['dashboard'])
  const defaultLanguage = CookiesHelper.getItem('defaultLanguage') || 'de'

  useEffect(() => {
    dashboardActions.displayServicesOnDashboard()
  }, [])

  const handleServiceClick = (url) => {
    window.open(url)
  }

  const handleLogout = () => {
    showSnackBar(open, { displayMsg: authState?.authResponse })
    authActions.tryLogout()
    goToHomePage()
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const onLanguageChange = (lang) => {
    CookiesHelper.setItem('defaultLanguage', lang, 604800, '/')
    window.location.reload()
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }} className='header-wrapper'>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-assets-helloparts/logos/helloparts-Logo-x.png' alt='HelloParts' style={{ height: '30px', marginRight: '10px' }} />
        </Box>
        <Typography variant='h5' className='clr_white gp-ml-auto'>{t('header')}</Typography>
        <div className='gp-ml-auto'>
          <Select
            className='lng-selector-dd'
            value={defaultLanguage}
            onChange={(e) => onLanguageChange(e.target.value)}
            sx={{ width: 150 }}
          >
            <MenuItem value='en'>English</MenuItem>
            <MenuItem value='de'>German</MenuItem>
          </Select>
        </div>
        <div className='profile-view'>
          <Button
            id='basic-button'
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            onClick={handleMenuOpen}
          >
            <div className='vertical-center'><AccountCircle className='mr8' /><span className='mr8 txt-none'>{dashboardState?.dashboardData?.first_name}</span> <KeyboardArrowDownIcon className='clr_white' /></div>
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
          </Menu>
        </div>
      </Box>
      <Container component='main' maxWidth='md'>
        <Box sx={{
          top: '40%',
          left: '25%',
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          justifyContent: 'center',
          transform: 'translate(-50 %, -50 %)',
          overflow: 'auto',
          minHeight: '100vh',
          padding: '20px'
        }}
        >
          <CssBaseline />
          <Grid container spacing={2} sx={{ marginTop: '20px' }}>
            {dashboardState?.dashboardData?.services?.map((service, index) => (
              <Grid key={index} item xs={6} sm={4} md={3}>
                <Card
                  variant='elevation'
                  onClick={() => handleServiceClick(service.url)}
                  sx={{
                    width: '180px',
                    height: '160px',
                    textAlign: 'center',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    overflow: 'hidden',
                    cursor: 'pointer',
                    margin: '20px 0',
                    transition: 'transform 0.3s, border 0.3s',
                    backdropFilter: 'blur(16px) saturate(180%)',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '12px',
                    border: '1px solid rgba(255, 255, 255, 0.125)',
                    '&:hover': {
                      transform: 'translateY(-5px)'
                    }
                  }}
                >
                  <CardContent>
                    <IconButton>
                      <img src='https://d3brnpc5nhvc9v.cloudfront.net/static-images/parts_finder_img.png' className='w-70 h-70' loading='lazy' />
                    </IconButton>
                    <Typography>{service.service_name || service.name}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </ThemeProvider>
  )
}

export default Dashboard
